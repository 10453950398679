import React from "react";
import cn from "classnames";
import "modern-normalize/modern-normalize.css";
import "../styles/global.css";
import "../styles/fonts.scss";

import * as styles from "./styles.module.scss";

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children, className }: IProps) => (
  <>
    <div className={cn(styles.container, className)}>{children}</div>
  </>
);

export default Layout;
